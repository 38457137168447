
/* -----
Font Family
----- */
$calibre:'Calibre Regular', sans-serif;
$calibresm:'Calibre Semibold', sans-serif;
$eksell:'Eksell Display', sans-serif;
$font: $calibre;


/* -----
Font Weight
----- */
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

h1 {

  clip: rect(1px,1px,1px,1px);
  height: 1px;
  overflow: hidden;
  position: absolute!important;
  width: 1px;
  display: block;
  margin: 0;
  padding: 0;

  @include font-size(48, $mobile);

  @include media-query("b-l-up") {
    @include font-size(120, $desktop);
  }

}

h2 {

  font-family: $eksell;
  line-height: 1;
  text-align: left;

  @include font-size(58, $mobile);
  margin-bottom: tovw(30, $mobile);

  @include media-query("b-s-up") {

    margin-bottom: tovw(48, $desktop);
    padding-top: tovw(64, $desktop);
    @include font-size(120, $desktop);
  }
}

h3 {

  font-family: $eksell;
  line-height: 1;
  margin: 0;
  margin-bottom: tovw(60, $mobile);

  @include font-size(40, $mobile);

  @include media-query("b-s-up")  {
    @include font-size(30, $tablet);
    margin-bottom: tovw(40, $desktop);
  }

  @include media-query("b-m-up") {

    @include font-size(48, $desktop);
  }
}

h4 {

  font-family: $eksell;
  line-height: 1;
  margin: 0;
  margin-bottom: 0;

  @include font-size(22, $mobile);

  @include media-query("b-s-up")  {
    @include font-size(20, $tablet);
  }

  @include media-query("b-m-up") {
    @include font-size(24, $desktop);
  }
}


p, li, span, div, a {
  
  font-family: $calibre;
  letter-spacing: tovw(0.2, $mobile);

  @include font-size(16, $mobile);

  @include media-query("b-s-up") {

    letter-spacing: tovw(0.2, $desktop);
    @include font-size(18, $tablet);
  }

  @include media-query("b-m-up") {
    @include font-size(18, $desktop);
  }

}

p {
  margin-bottom : tovw(30, $mobile);

  @include media-query("b-s-up") {
    margin-bottom : tovw(30, $desktop);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}