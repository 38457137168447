
@charset "UTF-8";


@import 'modules/normalize';

@import "modules/variables";
@import "modules/medias";
@import "modules/mixins";
@import "modules/fonts";
@import "modules/easings";

@import "../../node_modules/aos/dist/aos";

//@import "modules/keyframes";



* {
	margin: 0;
	padding: 0;

	box-sizing: border-box;

	text-rendering: optimizeLegibility;
	
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
  line-height: 1.2;
  overflow-y: scroll;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

}

.red {
  color: $red;
}

.content {

  padding: 0 tovw(24, $mobile);
  position: relative;
  width: 100%;

  @include media-query("b-l-up") {
    padding: 0 tovw(130, $desktop);
  }

  .s3-content-txt,
  .s5-content-txt {
    display: inline-block;
  }
}


header {
  position: relative;
}

.is-hidden {
  opacity: 0;
  visibility: hidden;
}

.menu, 
.burger {
  position: absolute;
  right: tovw(24, $mobile);
  top: 40px;
  z-index: 999;

  @include media-query("b-l-up") {
    right: tovw(130, $desktop);
  }
}

.burger {
  display: block;
  cursor: pointer;

  svg {
    pointer-events: none;
  }
}

.menu {
  display: none;
  background: $red;
  overflow: hidden;
  padding: 3vw 6vw;
  @include media-query("b-l-up") {
    padding: 1.8vw 3vw;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 0.5vw;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: white;
    display: inline-block;
    font-family: $calibresm;
    font-weight: bold;
    position: relative;
    text-decoration: none;

    &::after {
      background: white;
      content: "";
      height: 3px;
      position:absolute;
      right: -100px;
      top: 50%;
      transform: translateX(calc(100% + 8px));
      transition: transform 0.3s ease;
      width: 100px;
    }

    &:hover {
      &::after {
        transform: translateX(calc(0% + 8px));
      }
    }
  }
}

#logo {
  display: block;
  height: tovw(165, $mobile);
  position: absolute;
  top: 0;
  width: auto;

  @include media-query("b-s-up") {
    
    height: auto;
    margin-bottom: tovw(50, $desktop);
    position: relative;
    width: tovw(740, $desktop);
  }

  img {
    display: inline-block;
    height: 100%;
    margin-top: tovw(-28, $desktop);
    width: auto;

    @include media-query("b-s-up") {
      height: auto;
      margin-left: tovw(130, $desktop);
      transform: translateX(tovw(-70, $desktop));
      width: 100%;
    }
  }
}

#single-bg-wrap {
  height: tovw(214, $mobile);
  margin-bottom: tovw(40, $mobile);
  overflow: hidden;
  position: relative;
  width: 100%;

  @include media-query("b-s-up") {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    height: tovw(840, $desktop);
    width: tovw(1080, $desktop);
  }
}

#header-bg-wrap {
  height: tovw(414, $mobile);
  margin-bottom: tovw(40, $mobile);
  overflow: hidden;
  position: relative;
  width: 100%;

  @include media-query("b-s-up") {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    height: tovw(840, $desktop);
    width: tovw(1080, $desktop);
  }
}

.header-bg {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100%;
  top: 0;
  position: absolute;
  width: 100%;

  @include media-query("b-s-up") {
    background-position: top;
  }
}

.s-bg {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  opacity: 0;
  height: 100%;
  top: 0;
  position: absolute;
  visibility: hidden;
  width: 100%;

  &:first-of-type {
    opacity: 1;
    visibility: visible;
  }

  @include media-query("b-s-up") {
    background-position: top;
  }
}


.s-bg-2 {
  display: none;
}

#header-bg-1 {
  
  background-image: url(#{$img-path}/header-bg-600.jpg);

  @include media-query("b-s-up") {
    background-image: url(#{$img-path}/header-bg-940.jpg);
  }
  
  @include media-query("b-l-up") {
    background-image: url(#{$img-path}/header-bg-1280.jpg);
  }

  @include media-query("b-xxl-up") {
    background-image: url(#{$img-path}/header-bg-2000.jpg);
  }
}

#header-bg-2 {
  
  background-image: url(#{$img-path}/header-layer-600.png);
  position: relative;
  z-index: 2;

  @include media-query("b-s-up") {
    background-image: url(#{$img-path}/header-layer-940.png);
  }
  
  @include media-query("b-l-up") {
    background-image: url(#{$img-path}/header-layer-1280.png);
  }

  @include media-query("b-xxl-up") {
    background-image: url(#{$img-path}/header-layer-2000.png);
  }
}

#header-content {

  position: relative;
  z-index: 2;
  
  @include media-query("b-s-up") {
    width: tovw(480, $desktop)
  }
}


#s-s1, 
#s-s2,
#s-s3,
#s-s4,
#s-s5 {
  padding-top: tovw(65, $mobile);
  position: relative;

  @include media-query("b-s-up") {
    padding-top: tovw(120, $desktop);
  }
} 

#s-s1 {
  @include media-query("b-s-up") {
    padding-top: tovw(80, $desktop);
  }
}

#s1-cards-wrap,
#s2-cards-wrap,
#s3-cards-wrap,
#s4-cards-wrap,
#s5-cards-wrap {

  font-size: 0;
  //transition: transform 0.3s ease;
  @include media-query("b-s-up") {
    display: flex;
    flex-wrap: wrap;
    margin-left: tovw(-16, $tablet);
  }
  @include media-query("b-m-up") {
    margin-left: tovw(-31, $desktop);
    margin-right: tovw(-31, $desktop);
  }
}

#s1-cards,
#s2-cards,
#s3-cards,
#s4-cards,
#s5-cards {

  position: relative;
  width: 100%;

  @include media-query("b-s-up") {
    padding: 0 tovw(24, $mobile);
  }

  @include media-query("b-l-up") {
    padding: 0 tovw(130, $desktop);
  }
}

#s-s1 .card,
#s-s3 .card {
  background: $red;
}

#s-s2 .card,
#s-s4 .card,
#s-s5 .card {
  background: black;
}

#s2-description,
#s3-description,
#s4-description {
  margin-bottom: tovw(50, $mobile);
  text-align: left;
  width: 100%;

  @include media-query("b-s-up") {
    margin-bottom: tovw(32, $tablet);
    width: tovw(340, $tablet);
  }

  @include media-query("b-m-up") {
    margin-bottom: tovw(64, $desktop);
    width: tovw(340, $desktop);
  }
}

#s-s3 .content,
#s-s5 .content {
  @include media-query("b-s-up") {
    text-align: right;
  }
}



#s1-cards-wrap,
#s5-cards-wrap {
  
  @include media-query("b-s-up") {
    text-align: right;
  }

  .card:last-child {
    margin-right: 0;
  }
}

#s1-bg-wrap  {

  display: none;

  @include media-query("b-s-up") {

    display: block;
    margin-bottom: 0;
    position: absolute;
    left: tovw(-150, $desktop);
    height: tovw(850, $desktop);
    top: tovw(350, $desktop);
    width: tovw(1080, $desktop);
  }
}

#s1-cards-container,
#s2-cards-container,
#s3-cards-container,
#s4-cards-container {
  overflow: hidden;
}

#s1-cards-wrap,
#s2-cards-wrap,
#s3-cards-wrap,
#s4-cards-wrap {
  display: flex;
  width: calc(100vw * 5);

  @include media-query("b-s-up") {
    //display: block;
    //justify-content: flex-end;
    width: 100%;
  }
}

#s1-cards-wrap {
  @include media-query("b-s-up") {
    justify-content: flex-end;
  }
}


#s1-bg {

  background-image: url(#{$img-path}/s1-bg-600.jpg);
  background-size: contain;
  
  @include media-query("b-s-up") {
    background-image: url(#{$img-path}/s1-bg-940.jpg);
  }
  
  @include media-query("b-l-up") {
    background-image: url(#{$img-path}/s1-bg-1280.jpg);
  }

  @include media-query("b-xxl-up") {
    background-image: url(#{$img-path}/s1-bg-2000.jpg);
  }
}


#s2-bg-wrap  {
  
  height: 100vw;
  margin-bottom: tovw(40, $mobile);
  position: relative;
  width: 100%;

  @include media-query("b-s-up") {

    display: block;
    margin-bottom: 0;
    position: absolute;
    right: 0;
    height: tovw(170, $mobile);
    top: tovw(120, $desktop);
    width: tovw(120, $mobile);
  }

  @include media-query("b-m-up") {
    height: tovw(430, $tablet);
    width: tovw(452, $tablet);
  }

  @include media-query("b-l-up") {
    height: tovw(880, $desktop);
    width: tovw(896, $desktop);
   
  }

}

#s3-bg-wrap  {

  height: 100vw;
  margin-bottom: tovw(40, $mobile);
  position: relative;
  width: 100%;

  @include media-query("b-s-up") {

    display: block;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    height: tovw(170, $mobile);
    top: tovw(120, $desktop);
    width: tovw(120, $mobile);
  }

  @include media-query("b-m-up") {
    height: tovw(420, $tablet);
    width: tovw(550, $tablet);
  }

  @include media-query("b-l-up") {
    height: tovw(850, $desktop);
    width: tovw(1050, $desktop);
  }

}


#s4-bg-wrap  {

  height: 100vw;
  margin-bottom: tovw(40, $mobile);
  position: relative;
  width: 100%;

  @include media-query("b-s-up") {

    display: block;
    margin-bottom: 0;
    position: absolute;
    right: 0;
    height: tovw(170, $mobile);
    top: tovw(120, $desktop);
    width: tovw(120, $mobile);
  }

  @include media-query("b-m-up") {
    height: tovw(410, $tablet);
    width: tovw(350, $tablet);
  }

  @include media-query("b-l-up") {
    height: tovw(820, $desktop);
    width: tovw(700, $desktop);
  }

}


#s5-bg-wrap  {

  height: 100vw;
  margin-bottom: tovw(40, $mobile);
  position: relative;
  width: 100%;

  @include media-query("b-s-up") {

    display: block;
    margin-bottom: 0;
    position: absolute;
    left: 0;
    height: tovw(170, $mobile);
    top: tovw(120, $desktop);
    width: tovw(120, $mobile);
  }

  @include media-query("b-m-up") {
    height: tovw(410, $tablet);
    width: tovw(350, $tablet);
  }

  @include media-query("b-l-up") {
    height: tovw(800, $desktop);
    width: tovw(800, $desktop);
  }

}

.s5-content-txt h2{
  padding-top: tovw( 200,$desktop)
}




.card {

  color: white;
  display: inline-block;
  height: 100vw;
  overflow: hidden;
  padding: tovw(30, $mobile) tovw(20, $mobile);
  position: relative;
  text-align: left;
  text-decoration: none;
  margin: 0;
  transition: background 0.5s $easeInOutCubic;
  width: 100vw;

  @include media-query("b-s-up") {

    height: tovw(289, $tablet);
    margin-bottom: tovw(32, $tablet);
    margin-left: tovw(16, $tablet);
    padding: tovw(25, $tablet);
    width: calc(50% - #{tovw(16, $tablet)} )
  }
  

  @include media-query("b-m-up") {

    height: tovw(182, $tablet);
    margin-bottom: tovw(62, $tablet);
    margin-left: tovw(24, $desktop);
    margin-right: tovw(24, $desktop);
    padding: tovw(15, $tablet);
    width: calc(33.33% - #{tovw(48, $desktop)} )
    
  }

  @include media-query("b-l-up") {
    height: tovw(350, $desktop);
    margin-bottom: tovw(62, $desktop);
    padding: tovw(30, $desktop);
    

  }

  &.js-open {
    .card-corner {
      display: block;
    }
  }

  &.js-hover {
    img {
      display: none;
    }
  }

  &.slide-1 {
    
  
    @include media-query("b-m-up") {
      &:hover {
        background: black !important;

        .card-date {
          transform: translateY(-200%);
        }
    
        .card-title {
          transform: translateY(-200%);
        }
    
        .card-corner {
          transform: translate(200%, 200%);
        }
    
        .card-static {
          opacity: 0;
        }
    
        .card-over {
          opacity: 1;
          transform: translateY(0%);
        }
      }
    }

  }

  &.active {

    background: black !important;
    
    .card-date {
      transform: translateY(-200px);
    }

    .card-title {
      transform: translateY(-200px);
    }
    
    .card-subtitle {
      transform: translateY(-200px);
    }

    .card-corner {
      transform-origin: center;
      transform: rotate(45deg);
    }

    .card-static .card-txt,
    .card-thumb {
      opacity: 0; 
    }
    .card-over {
      opacity: 1;
      transform: translateY(0%);
      //transition-delay: 0.3s;
    }
  }

  &.slide-2 {

    .card-static,
    .card-over {
      transition: transform 0.4s $easeInOutCubic;
    }

    .card-over {
      position:relative;
      opacity: 1;
    }

    &:hover {
      background: black !important;
      
      .card-static,
      .card-over {
        transform: translateY(-150%);
      }

    }
  }

  &.slide-3 {

    @include media-query("b-m-up") {
      &:hover {
        background: $red !important;

        a {
          transition: all 0.5s ease;
          text-decoration-color: white;
        }

        .card-title {
          transform: translateY(-200%);
        }

        .card-subtitle {
          transform: translateY(-200px);
        }
    
        .card-corner {
          transform: translate(200%, 200%);
        }

        .card-over {
          opacity: 1;
          transform: translateY(0%);
        }
      }
    }
  }


  .card-sprite {
    display: none;
  }

  .card-txt-wrap {
    height: 100%;
    position: relative;

    h3 {
      margin: 0;
    }
  }

  .card-static {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    transition: opacity 0.3s $easeInOutCubic;
  }

  .card-over {
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(550px);
    transition: transform, opacity 0.4s ease;

  }

  .card-date {
    color: inherit;
    font-family: $calibresm;
    transition: transform 0.35s $easeInOutCubic;
  }

  .card-title {
    color: inherit;
    transition: transform 0.35s $easeInOutCubic;
  }

  .card-link {
    color: white;
    font-family: $calibresm;
    text-decoration: underline;
    text-decoration-color: $red;
    font-weight: normal;
  }

  .card-txt {
    color: inherit;
    font-family: $calibresm;
    margin-bottom: 0;
    transition: opacity 0.35s $easeInOutCubic;

    p {
      font-family: $calibresm;
    }

    a {
      color: white;
      font-family: $calibresm;
      text-decoration-color: $red;
    }
  }

  .card-thumb {

    bottom: tovw(15, $desktop);
    position: absolute;
    left: 0;
    width: tovw(100, $mobile);

    @include media-query("b-s-up") {

      height: tovw(215, $desktop);
      width: tovw(180, $desktop);
    }

    @include media-query("b-m-up") {
      left: auto;
      right: tovw(15, $desktop);
      height: tovw(135, $desktop);
      width: tovw(100, $desktop);
    }

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .card-link {
    bottom: 0;
    color: white;
    display: block;
    text-decoration-color: $red;
    position: absolute;
    z-index: 1;
  }

  .card-corner {
    align-self: flex-end;
    border: tovw(2, $mobile) solid;
    border-radius: 50%;
    bottom: 0;
    display: none;
    height: 60px;
    position: relative;
    width: 60px;
    transition: transform 0.35s $easeInOutCubic;
    z-index: 1;

    &::before {
      
      background: white;
      content: "";
      height: 2px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: tovw(12, $mobile);
      @include media-query("b-s-up") {
        width: tovw(5, $mobile);
      }
      @include media-query("b-m-up")  {
        display: none;
      }
    }

    &::after {
      background: white;
      content: "";
      height: tovw(12, $mobile);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 2px;
      @include media-query("b-s-up") {
        height: tovw(5, $mobile);
      }
      @include media-query("b-m-up")  {
        display: none;
      }
    }

    @include media-query("b-s-up") {
      
      border: tovw(2, $tablet) solid;
      display: block;
      height: 30px;
      width: 30px;
    }

    @include media-query("b-m-up") {
      border: 0;
      border-bottom: tovw(6, $desktop) solid;
      border-right: tovw(6, $desktop) solid;
      border-radius: 0;
      display: block;
      height: tovw(48, $desktop);
      width: tovw(48, $desktop);
    }
  }
}


.swiper-pagination {
  display: flex;
  justify-content: center;
  padding: 0 tovw(20, $mobile);
  padding-top: tovw(30, $mobile);
  width: 100%;

  @include media-query("b-s-up") {
    display: none;
  }

  &.red-nav {
    .swiper-pagination-bullet {
      border: 1px solid $red;
    }
    .swiper-pagination-bullet-active {
      background: $red;
    }
  }

  &.black-nav {
    .swiper-pagination-bullet {
      border: 1px solid black;
    }
    .swiper-pagination-bullet-active {
      background: black;
    }
  }

  .swiper-pagination-bullet {

    border-radius: 50%;
    display: block;
    height: tovw(10, $mobile);
    margin: 0 tovw(10, $mobile);
    width: tovw(10, $mobile);
  }

}

.preloader {

  background: #fafafa;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top:0;
  width: 100%;
  z-index: 999;
}

.sprite {

  width:100px;
  height:100px;
  opacity: 0;
  background:url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/42764/kape-sprite.png);  
  background-size: 2100px ;
  position: fixed;
  top: calc(100vh/2);
  left: 50%;
  transform: translate(-50% ,-50%);
  background-position:  0px; 

  &.animate {
    opacity: 1;
    animation: sprite 1.5s steps(20, end);
    
  }
}


@keyframes sprite {
  from { background-position:   100px; }
  to { background-position: 2100px; }
}


#single-header {

  margin-bottom: tovw(188, $desktop);
  position: relative;

  .card {
    background: $red;
    margin-left: 0;
    max-width: 100%;
  }

  .card-txt-wrap {

    display: flex;
    flex-direction: column;
    justify-content: space-around;
  
  }

  .card-link {
    position: relative;
    text-decoration-color: white;
  }
}

.single-close {
  position: absolute;
  background: $red;
  width: tovw(40, $mobile);
  height: tovw(40, $mobile);
  border-radius: 50%;
  right: 7vw;

  top: tovw(50, $desktop);
  z-index: 999;

  @include media-query("b-s-up") {
    width: tovw(60, $tablet);
    height: tovw(60, $tablet);
    right: tovw(130, $desktop);
  }

  @include media-query("b-m-up") {
    width: tovw(60, $desktop);
    height: tovw(60, $desktop);
  }

  a {

    display: block;
    height: 100%;
    transform: rotate(45deg);
    width: 100%;

    &::before {
      
      background: white;
      content: "";
      height: tovw(6, $desktop);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: tovw(12, $mobile);
      @include media-query("b-s-up") {
        width: tovw(5, $mobile);
      }
      
    }

    &::after {
      background: white;
      content: "";
      height: tovw(12, $mobile);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: tovw(6, $desktop);
      @include media-query("b-s-up") {
        height: tovw(5, $mobile);
      }
      
    }
  }
}

.single-images {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .single-images--column {

    width: 100%;
    
    @include media-query("b-s-up") {
      width: calc( 50% - (#{tovw(62, $desktop)}/2) );
    }

    &:first-child {
      margin-top: tovw(-120,$desktop);
    }
  }

  .gallery-item {
    display: block;
    margin-bottom: tovw(40, $mobile);

    @include media-query("b-s-up") {
      margin-bottom: tovw(62, $desktop);
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.social {

  align-items: center;
  display: flex;
  margin-top: 100px;
  list-style: none;
  padding: 0 tovw(24, $mobile);
  padding-bottom: 100px;
  position: relative;
  width: 100%;

  @include media-query("b-l-up") {
    padding: 0 tovw(130, $desktop);
    padding-bottom: 100px;
    position: absolute;
    right: 0;
    margin-top: 0;
    width: auto;
  }

  li {

    margin: 0 12px;

  }

  svg {

    display: block;
    fill: black;
    transition: fill 0.3s ease;
    &:hover {
      fill: $red;
    }
  }
}