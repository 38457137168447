/* -----
Path
----- */
$img-path: "../assets/images";
$fonts-path: "../assets/fonts/";

/* -----
Colors
----- */

$red: #FF443F;
$none: rgba(0,0,0,0);


/* -----
Design
----- */

$desktop: 1440;
$tablet: 720;
$mobile: 320;
