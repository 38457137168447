

/* -----
Mixins
----- */

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin hidden {
  opacity: 0;
  visibility: hidden;
}
@mixin show {
  opacity: 1;
  visibility: visible;
}

@mixin center_block{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@mixin scale_width_grid{
    max-width:100%;
    height: auto;
}

@mixin absolute{
     position: absolute;
     top: 0; right: 0; bottom: 0; left:0;
     margin: auto;
}

@mixin fixed{
     position: fixed;
     top: 0; right: 0; bottom: 0; left:0;
     margin: auto;
}

@mixin size($width, $height){
  width: $width;
  height: $height;
}


@mixin font-size($size, $device){
  font-size: (($size * 100) / $device) + vw;
}


@function tovw($size, $device){
  @return (($size * 100) / $device) + vw;
}

@mixin media-query($mq) {

  $breakpoint-found: false;

  // Loop through the list of breakpoints we’ve provided in our settings file.
  @each $breakpoint in $breakpoints {

    // Grab the alias and the condition from their respective locations in
    // the list.
    $alias:     nth($breakpoint, 1);
    $condition: nth($breakpoint, 2);

    // If the media query we’ve specified has an alias and a condition...
    @if $mq == $alias and $condition {

      // ...tell the mixin that we’ve found it...
      $breakpoint-found: true;

      // ...and spit it out here.
      @media #{$condition} {
        @content;
      }

    }

  }

  // If the user specifies a non-exitent alias, send them a warning.
  @if $breakpoint-found == false {
    @warn "Oops! Breakpoint ‘#{$mq}’ does not exist.";
  }

}